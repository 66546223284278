<template>
  <div>
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      <div>
        Транзакции ({{ total }})
        <i v-if="loading" class="el-icon-loading"></i>
      </div>
      <div class="flex items-center justify-end">
        <el-button @click="create">Создать</el-button>
      </div>
    </h1>

    <div>
      <el-select
        v-model="filters.account"
        @change="handleFiltersChange"
        clearable
        size="small"
        placeholder="Выберите счет"
        class="mr-2 mb-3"
      >
        <el-option
          v-for="x in accounts"
          :key="x.id"
          :label="x.name"
          :value="x.id"
        ></el-option>
      </el-select>

      <el-select
        v-model="filters.type"
        @change="handleFiltersChange"
        clearable
        size="small"
        placeholder="Тип операции"
        class="mr-2 mb-3"
      >
        <el-option label="Доход" value="income"></el-option>
        <el-option label="Расход" value="outcome"></el-option>
        <el-option label="Перевод" value="transfer"></el-option>
      </el-select>

      <el-select
        v-model="filters.category_id"
        @change="handleFiltersChange"
        clearable
        class="mr-2 mb-3"
        size="small"
        placeholder="Выберите категорию"
      >
        <el-option
          v-for="x in categories"
          :key="x.id"
          :label="`${x.name}`"
          :value="x.id"
        ></el-option>
      </el-select>

      <el-select
        v-model="filters.city_id"
        @change="handleFiltersChange"
        clearable
        class="mr-2 mb-3"
        size="small"
        placeholder="Выдерите город"
      >
        <el-option
          v-for="x in cities"
          :label="x.name"
          :value="x.id"
          :key="x.id"
        ></el-option>
      </el-select>

      <el-date-picker
        v-model="filters.date"
        @change="handleFiltersChange"
        clearable
        size="small"
        placeholder="Выберите дату"
        format="dd-MM-yyyy"
        value-format="dd-MM-yyyy"
        class="mb-3"
      ></el-date-picker>
    </div>

    <el-table border size="small" stripe :data="transactions">
      <el-table-column prop="account_id" label="Счет">
        <template v-slot="scope">
          {{ getAccountName(scope.row.account_id) }}
        </template>
      </el-table-column>
      <el-table-column prop="is_income" label="Тип операции">
        <template v-slot="scope">
          <div
            :class="scope.row.type"
            class="transaction-type rounded-2xl px-2 text-white"
          >
            <i v-if="scope.row.type === 'income'" class="el-icon-download"></i>
            <i
              v-else-if="scope.row.type === 'outcome'"
              class="el-icon-upload2"
            ></i>
            <i v-else class="el-icon-refresh"></i>
            {{
              $options.transactionTypeTitles[scope.row.type] || scope.row.type
            }}
          </div>

          <div v-if="scope.row.type === 'transfer'">
            {{ getAccountName(scope.row.account_id) }}
            <i class="el-icon-right"></i>
            {{ getAccountName(scope.row.receiver_account_id) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="category_id" label="Категория">
        <template v-slot="scope">
          {{ getCategoryName(scope.row.category_id) }}
        </template>
      </el-table-column>
      <el-table-column prop="city_id" label="Город">
        <template v-slot="scope">
          {{ getCityName(scope.row.city_id) }}
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="Дата">
        <template v-slot="scope">
          {{ dateFormat(scope.row.created_at * 1000) }}
        </template>
      </el-table-column>
      <el-table-column prop="sum" label="Сумма">
        <template v-slot="scope">
          {{ scope.row.sum }}
        </template>
      </el-table-column>
      <el-table-column prop="comment" label="Коммент">
        <template v-slot="scope">
          {{ scope.row.comment }}
          <div v-if="scope.row.accounting_id">
            <router-link
              class="cursor-pointer text-blue-500 underline"
              target="_blank"
              :to="{
                name: 'accounting-item',
                params: { id: scope.row.accounting_id },
              }"
            >
              {{ scope.row.accounting_id }}
            </router-link>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="text-center my-5"
      background
      :disabled="loading"
      layout="prev, pager, next"
      :total="total"
      :page-size="20"
      :current-page.sync="filters.page"
      @current-change="() => handlePageChange()"
    >
    </el-pagination>

    <el-drawer
      :visible.sync="drawer"
      :with-header="false"
      :before-close="closeDrawer"
      :destroy-on-close="true"
      :wrapperClosable="false"
    >
      <template>
        <div
          class="border-b p-4 font-medium text-lg flex justify-between items-center"
        >
          Добавить транзакцию
          <i
            @click="() => closeDrawer()"
            class="el-icon-close cursor-pointer"
          ></i>
        </div>
        <div class="p-4">
          <div class="relative mb-4">
            <div class="mb-1 text-gray-500">Тип операции</div>
            <el-select
              v-model="form.type"
              @change="resetFormState"
              class="w-full"
              size="medium"
            >
              <el-option label="Доход" value="income"></el-option>
              <el-option label="Расход" value="outcome"></el-option>
              <el-option label="Перевод" value="transfer"></el-option>
            </el-select>
          </div>

          <div v-if="form.type !== 'transfer'" class="relative mb-4">
            <div class="mb-1 text-gray-500">Город</div>
            <el-select
              v-model="form.city_id"
              clearable
              class="w-full"
              size="medium"
            >
              <el-option
                v-for="x in cities"
                :label="x.name"
                :value="x.id"
                :key="x.id"
              ></el-option>
            </el-select>
          </div>

          <div class="relative mb-4">
            <div class="mb-1 text-gray-500">Счет</div>
            <el-select v-model="form.account_id" class="w-full" size="medium">
              <el-option
                v-for="x in accounts"
                :key="x.id"
                :label="`${x.name} - ${x.balance}тг`"
                :value="x.id"
              ></el-option>
            </el-select>
          </div>

          <div v-if="form.type === 'transfer'" class="relative mb-4">
            <div class="mb-1 text-gray-500">На счет</div>
            <el-select
              v-model="form.receiver_account_id"
              class="w-full"
              size="medium"
            >
              <el-option
                v-for="x in accounts"
                :key="x.id"
                :label="`${x.name} - ${x.balance}тг`"
                :value="x.id"
              ></el-option>
            </el-select>
          </div>

          <div v-if="form.type !== 'transfer'" class="relative mb-4">
            <div class="mb-1 text-gray-500">Категория</div>
            <el-select v-model="form.category_id" class="w-full" size="medium">
              <el-option
                v-for="x in categories"
                :key="x.id"
                :label="`${x.name}`"
                :value="x.id"
              ></el-option>
            </el-select>
          </div>
          <div class="relative mb-4">
            <div class="mb-1 text-gray-500">Дата операции</div>
            <el-date-picker
              v-model="form.created_at"
              size="medium"
              type="date"
              placeholder=""
              format="dd-MM-yyyy"
              value-format="dd-MM-yyyy"
            >
            </el-date-picker>
          </div>
          <div class="relative mb-4">
            <div class="mb-1 text-gray-500">Коммент</div>
            <el-input
              type="textarea"
              size="medium"
              class="w-full"
              v-model="form.comment"
            ></el-input>
          </div>
          <div class="relative mb-4">
            <div class="mb-1 text-gray-500">Сумма</div>
            <el-input
              size="medium"
              class="w-full"
              type="number"
              v-model.number="form.sum"
            ></el-input>
          </div>

          <div class="text-right">
            <el-button
              @click="save"
              :disabled="
                loading ||
                !form.account_id ||
                !form.sum ||
                !form.comment.trim() ||
                (this.form.type !== 'transfer' && !form.city_id)
              "
              :icon="loading ? 'el-icon-loading' : ''"
              size="medium"
              type="primary"
              round
              plain
              >Сохранить</el-button
            >
          </div>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script>
import {
  getAccounts,
  getCategories,
  getTransactions,
  createTransaction,
} from "@/api/transactions";
import { getCities } from "@/api/branch";
import { format } from "date-fns";

export default {
  name: "transactions",
  transactionTypeTitles: {
    income: "Доход",
    outcome: "Расход",
    transfer: "Перевод",
  },
  data() {
    return {
      loading: false,
      drawer: false,
      total: 0,
      transactions: [],

      form: {
        account_id: null,
        receiver_account_id: null,
        type: "outcome",
        category_id: null,
        created_at: null,
        sum: 0,
        city_id: null,
        comment: null,
      },

      accounts: [],
      categories: [],
      cities: [],

      filters: {
        page: 1,
        account: null,
        type: null,
        category_id: null,
        city_id: null,
        date: null,
      },
    };
  },
  async mounted() {
    await this.getTransactions();
    this.accounts = await getAccounts();
    this.categories = await getCategories();
    this.cities = await getCities();
    const today = new Date();
    this.form.created_at = `${today.getDate()}-${
      today.getMonth() + 1
    }-${today.getFullYear()}`;
  },
  methods: {
    async getTransactions() {
      this.loading = true;
      const { transactions, total } = await getTransactions(this.filters);
      this.loading = false;
      this.transactions = transactions;
      this.total = total;
    },
    async handleFiltersChange() {
      this.filters.page = 1;
      await this.getTransactions();
    },
    async handlePageChange() {
      await this.getTransactions();
    },
    create() {
      this.drawer = true;
    },
    closeDrawer(done) {
      this.drawer = false;
      done && done();
    },
    async save() {
      this.loading = true;
      await createTransaction(this.form);
      this.loading = false;
      this.closeDrawer();

      this.resetFormState();
      this.transactions = await getTransactions();
    },

    resetFormState() {
      this.form.account_id = null;
      this.form.category_id = null;
      this.form.city_id = null;
      this.form.comment = null;
      this.form.receiver_account_id = null;
      this.form.sum = 0;
    },

    getAccountName(accountId) {
      const account = this.accounts.find((a) => a.id === accountId);
      return (account && account.name) || accountId;
    },
    getCategoryName(categoryId) {
      const category = this.categories.find((a) => a.id === categoryId);
      return (category && category.name) || categoryId;
    },
    getCityName(cityId) {
      const city = this.cities.find((a) => a.id === cityId);
      return (city && city.name) || cityId;
    },
    dateFormat(timestamp) {
      return format(timestamp, "dd MMM, yyyy");
    },
  },
};
</script>

<style scoped lang="scss">
.transaction-type {
  background-color: #2d3747;
  display: inline-block;
  &.income {
    background-color: #67c23a;
  }
  &.outcome {
    background-color: #f56c6c;
  }
}
</style>
