import apiClient from "@/api/apiClient";

export const getAccounts = async () => {
  const { data } = await apiClient.get("/api/accounts/");

  return data.accounts;
};

export const createAccount = async (name) => {
  const { data } = await apiClient.post("/api/accounts/", {
    name,
  });

  return data;
};

export const getCategories = async () => {
  const { data } = await apiClient.get("/api/accounts/categories/");

  return data.categories;
};

export const createCategory = async (name, is_delivery) => {
  const { data } = await apiClient.post("/api/accounts/categories/", {
    name,
    is_delivery,
  });

  return data;
};

export const getTransactions = async (filters) => {
  const { data } = await apiClient.get("/api/accounts/transactions/", {
    params: filters,
  });

  return data;
};

export const createTransaction = async (transaction) => {
  const { data } = await apiClient.post(
    "/api/accounts/transactions/",
    transaction
  );

  return data;
};
